import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_1CNovuVceMQFVwF3GfwF_J3tgASYi2LsRCYbeILrHnk from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_OiNyC4KQx0mYTJmLKuH_L2LC_uSkJf6HLi7trLK5cQE from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/vuetify-nuxt-module@0.18.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@22.13_770b34abb40273b012e0b1a1c3b154b5/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_91TON0KRhp2O2uXv_3dit4GpgG0_ZGCJ9zkHBtgA1QE from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ld_DiVKcowiPNsXiBNg9W0_baRshrga_rMZLzMYtaNY from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_A0obCwFYXIekqCGu_zW_5Yb7g4ujJqd0w4aOLluoZOg from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LAqJJWUg4LbEnd8sRN2C2e2kDtZ553YuFTdJqVWkF9o from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DGBLe_v93wMBPAC2K7KQPUhgT9QT74fK_frXFy4MzI0 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kB3GY_qDbR4fK8HYruMV0CouWGcRu77s9rj5mdCyJCc from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/addicted.nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_CXMZ6g8I8W8CpBNNhejz_b7HPhLa51_ci1slL0FsblE from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_bufferutil@4.0.9_db0@0.3.1_iored_e2c813fe480436f473f3d46bdce67077/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_0207495bb835a6f5ae7860b54eb3353f/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_iVNAERNrjwR0JZJ5IdAg_xKD3kfIYdIJToepjUPAR9g from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/vuetify-nuxt-module@0.18.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@22.13_770b34abb40273b012e0b1a1c3b154b5/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import cloudflare_client_oW5bnf83cb7GTw3aOBzDnr4mehF54Zf4RZop2HrWkT8 from "/opt/buildhome/repo/addicted.nuxt/plugins/cloudflare.client.ts";
import matomo_client_UybCn6fhPv7R9wxUYT7nkAadiBSV3zdRIuMtbFk_H5Q from "/opt/buildhome/repo/addicted.nuxt/plugins/matomo.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/opt/buildhome/repo/addicted.nuxt/plugins/sentry.client.ts";
import vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk from "/opt/buildhome/repo/addicted.nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_1CNovuVceMQFVwF3GfwF_J3tgASYi2LsRCYbeILrHnk,
  vuetify_icons_OiNyC4KQx0mYTJmLKuH_L2LC_uSkJf6HLi7trLK5cQE,
  unhead_91TON0KRhp2O2uXv_3dit4GpgG0_ZGCJ9zkHBtgA1QE,
  router_Ld_DiVKcowiPNsXiBNg9W0_baRshrga_rMZLzMYtaNY,
  payload_client_A0obCwFYXIekqCGu_zW_5Yb7g4ujJqd0w4aOLluoZOg,
  navigation_repaint_client_LAqJJWUg4LbEnd8sRN2C2e2kDtZ553YuFTdJqVWkF9o,
  check_outdated_build_client_DGBLe_v93wMBPAC2K7KQPUhgT9QT74fK_frXFy4MzI0,
  chunk_reload_client_kB3GY_qDbR4fK8HYruMV0CouWGcRu77s9rj5mdCyJCc,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_CXMZ6g8I8W8CpBNNhejz_b7HPhLa51_ci1slL0FsblE,
  plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E,
  vuetify_no_client_hints_iVNAERNrjwR0JZJ5IdAg_xKD3kfIYdIJToepjUPAR9g,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  cloudflare_client_oW5bnf83cb7GTw3aOBzDnr4mehF54Zf4RZop2HrWkT8,
  matomo_client_UybCn6fhPv7R9wxUYT7nkAadiBSV3zdRIuMtbFk_H5Q,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk
]