
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as apiVy43tO_bE8cJyqPTtfsJSIgJeZDdCO2mueGrg8QedicMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/api.vue?macro=true";
import { default as indexT6NLtrrzDjI7YJHIwXTJAEWWeiQ2vt90ZHG030zZWOIMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/index.vue?macro=true";
import { default as privacyGIjb3w6fl339aNdIGKpz1JgKgd4O4zc1xPXUAdYUy7gMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/privacy.vue?macro=true";
import { default as _91showName_93n9oVWCXqOYSG0qUl6p_45QC38IPMobyxzWono0HE_NvJsMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/shows/[showId]/[showName].vue?macro=true";
export default [
  {
    name: "Api",
    path: "/api",
    meta: apiVy43tO_bE8cJyqPTtfsJSIgJeZDdCO2mueGrg8QedicMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/api.vue")
  },
  {
    name: "Home",
    path: "/",
    meta: indexT6NLtrrzDjI7YJHIwXTJAEWWeiQ2vt90ZHG030zZWOIMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/index.vue")
  },
  {
    name: "Privacy Policy",
    path: "/privacy",
    meta: privacyGIjb3w6fl339aNdIGKpz1JgKgd4O4zc1xPXUAdYUy7gMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/privacy.vue")
  },
  {
    name: "show-details",
    path: "/shows/:showId()/:showName()",
    meta: _91showName_93n9oVWCXqOYSG0qUl6p_45QC38IPMobyxzWono0HE_NvJsMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/shows/[showId]/[showName].vue")
  }
]